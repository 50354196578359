import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

// star images
import starImg1 from "../../src/images/star1.png"
import starImg2 from "../../src/images/star2.png"
import starImg3 from "../../src/images/star3.png"
import starImg4 from "../../src/images/star4.png"
import starImg5 from "../../src/images/star5.png"
import starImg6 from "../../src/images/star6.png"
import starImg7 from "../../src/images/star7.png"
import starImg8 from "../../src/images/star8.png"
import errorImg from "../../src/images/404Errorimg.png"
import { Link } from "gatsby"

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>

        <Seo title="404: Not found" />

        <div className="mt-66 lg:mt-20 h-66clc mxn-h-66clc lg:h-80clc min-h-540 lg:max-h-80clc bg-dark-blue flex items-center justify-center">
          <div className="absolute inset-0 w-full h-full">
            <img
              src={starImg1}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-1 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg2}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-2 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg3}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-3 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg4}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-4 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg5}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-5 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg6}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-6 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg7}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-7 absolute inset-0 w-full h-full object-cover"
            />
            <img
              src={starImg8}
              alt="decodeup-galaxy-effect"
              className="cstm-star cstm-star-8 absolute inset-0 w-full h-full object-cover"
            />
          </div>

          <div className="relative flex h-full flex-col items-center justify-center z-10">
            <img src={errorImg} alt="decodeup-galaxy-effect" className="" />
            <h2 className="mb-6 text-center font-poppings-bold text-rh2 lg:text-h2 text-white">
              Page Not Found
            </h2>
            <Link
              className="py-2-2 px-6-6 bg-cyan-lite font-poppings-bold inline-block text-dark-blue text-rh5 md:text-rp3 lg:text-h5 0 shadow-1 hover:shadow-none transition-all duration-300 ease-in-out delay-0"
              href="/get-in-touch"
            >
              Back to home page
            </Link>
          </div>
        </div>
      </Layout>
    )
  )
}

export default NotFoundPage
